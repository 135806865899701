<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { HeaderContainer } from '@temperworks/components'
import { useCountryStore } from '../../store/country'
import { useUserStore } from '../../store/user'
import { useSettingsStore } from '../../store/settings'
import { useClientStore } from '../../store/client'
import unauthenticatedMenu from '../../composables/navigationUnAuth'
import clientTopNavigation from '../../composables/navigationAuth'
import clientDropdown from '../../composables/clientDropdown'
import clientMenuMobile from '../../composables/navigationAuthMobile'
import quickActionsClient from '../../composables/quickActionMenu'

const text = useI18n()
const countryStore = useCountryStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const clientStore = useClientStore()
const navigate = inject('navigate')
const route = useRoute()
const menuItems = ref<Array<string>>([])

const { clientFinOverviewNavigation } = useMigrationStatus()

function openCountrySelector() {
  countryStore.setCountryChange()
}

function logout() {
  navigate('/logout')
}

function signin() {
  navigate(countryStore.backend + '/login/identify/')
}

function stopImpersonation() {
  userStore.setImpersonation(false, null)
}
</script>

<template>
  <HeaderContainer :authenticated-user="userStore.loggedInUser ? true : false" :menu-items="userStore.loggedInUser ?
    clientTopNavigation(
      text,
      (userStore.userPermissions ? userStore.userPermissions : undefined),
      countryStore.backend,
      clientStore.onboardingCompleted,
      clientStore.openCheckouts
    ).clientTopNavigation :
    unauthenticatedMenu(text).unauthenticatedMenu
    " :menu-items-dropdown="userStore.loggedInUser ?
      clientDropdown(
        text,
        (userStore.userPermissions ? userStore.userPermissions : undefined),
        countryStore.backend,
        countryStore.country,
        countryStore.features,
        clientFinOverviewNavigation
      ).clientDropDown :
      menuItems
      " :menu-items-mobile="userStore.loggedInUser ?
        clientMenuMobile(
          text,
          (userStore.userPermissions ? userStore.userPermissions : undefined),
          countryStore.backend,
          countryStore.country,
          undefined,
          countryStore.features
        ).clientMenuMobile : []" :mobile-device="settingsStore.isTablet" :country="countryStore.country"
    :user="userStore.loggedInUser ? userStore.user : {}" :qa-menu="userStore.loggedInUser ?
      quickActionsClient(
        route.name,
        text,
        (userStore.userPermissions ? userStore.userPermissions : undefined),
        countryStore.backend).quickActionsClient :
      []
      " :is-impersonating="userStore.temperAdmin" :is-admin="userStore.temperAdmin" @signin="signin()"
    @stop-impersonation="stopImpersonation()" @country="openCountrySelector()" @logout="logout()" />
</template>
