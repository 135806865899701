import { useAuthStore } from '../store/auth'
import { computed, inject, ref, watch } from 'vue'

interface MigrationStatusRes {
  'has-invoices-temper-web': boolean
  'has-invoices-external-service': boolean
}

interface MigrationNavigationData {
  type: 'internal' | 'external'
  link: string
}

/**
 * temorarly solution to handle the migration to pfp platform
 * used to handle the navigation to the finance overview page
 */
export const useMigrationStatus = () => {
  const auth = useAuthStore()
  const store = useCountryStore()

  const fetchApi = inject('fetchApi') as Function
  const isLoading = ref(false)

  const migrationStatus = ref<MigrationStatusRes>({
    'has-invoices-temper-web': false,
    'has-invoices-external-service': false,
  })

  const fetchAlertStatus = async () => {
    const urlPath = '/api/v1/invoices/migration-status'

    isLoading.value = true
    try {
      const res = await fetchApi(urlPath, store.backend, {
        method: 'GET',
      })
      const data = (await res.json()) as MigrationStatusRes

      migrationStatus.value = data
    } catch (e) {
      console.error('Failed to fetch migration status', e)
      // we fallback to true because it's better to see an empty legacy
      // page than not seeing invoices at all
      migrationStatus.value = {
        'has-invoices-temper-web': true,
        'has-invoices-external-service': true,
      }
    } finally {
      isLoading.value = false
    }
  }

  watch(
    [store.backend, auth.token],
    () => {
      if (store.backend && auth.token && !isLoading.value) {
        fetchAlertStatus()
      }
    },
    { immediate: true },
  )

  const freeflexerFinOverviewNavigation = computed<MigrationNavigationData>(() => {
    const freeflexerUrlPath = '/dashboard/contractor/invoices'
    // france should always be internal
    if (store.country === 'fr') {
      return {
        type: 'internal',
        link: freeflexerUrlPath,
      }
    }

    // uk is depend on the flag
    if (store.country === 'uk') {
      if (migrationStatus.value['has-invoices-temper-web']) {
        return {
          type: 'internal',
          link: freeflexerUrlPath,
        }
      }
    }

    // Nl and rest possible should be external
    return {
      type: 'external',
      link: store.backend + freeflexerUrlPath,
    }
  })

  const clientFinOverviewNavigation = computed<MigrationNavigationData>(() => {
    const clientUrlPath = '/dashboard/client/invoices'
    // france should always be internal
    if (store.country === 'fr') {
      return {
        type: 'internal',
        link: clientUrlPath,
      }
    }

    // uk is depend on the flag
    if (store.country === 'uk') {
      if (migrationStatus.value['has-invoices-temper-web']) {
        return {
          type: 'internal',
          link: clientUrlPath,
        }
      }
    }

    // Nl and rest possible should be external
    return {
      type: 'external',
      link: store.backend + clientUrlPath,
    }
  })

  return {
    migrationStatus,
    freeflexerFinOverviewNavigation,
    clientFinOverviewNavigation,
  }
}
