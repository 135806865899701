/* eslint-disable no-prototype-builtins */
export default (root:any, permissions:any, be:string, onboarded:boolean, badge:any) => {
  const clientTopNavigation = [
    {
      id: 1,
      permission: !onboarded,
      link: '/get-started/',
      linktext: root.t('header.clients.onboardingDashboard'),
      name: 'get-started',
    },
    {
      id: 2,
      permission: onboarded,
      link: '/dashboard/',
      linktext: root.t('header.clients.dashboard'),
      name: 'dashboard',
    },
    {
      id: 3,
      link: be + '/dashboard/planning',
      linktext: root.t('header.clients.planning'),
      permission: true,
      name: 'dashboard-planning',
    },

    {
      id: 4,
      link: be + '/client/projects',
      linktext: root.t('header.clients.projects'),
      permission: permissions && permissions.hasOwnProperty('projects.index'),
      name: 'client-projects',
    },
    {
      id: 5,
      link: be + '/dashboard/checkouts',
      linktext: root.t('header.clients.checkout'),
      permission: permissions && permissions.hasOwnProperty('matches.checkouts.manage'),
      badge,
      name: 'dashboard-checkouts',
    },
    {
      id: 6,
      link: be + '/dashboard/client/flexpools',
      linktext: root.t('header.clients.flexpools'),
      permission: permissions && permissions.hasOwnProperty('flexpools.index'),
      name: 'dashboard-client-flexpools',
    },
  ]
  return {
    clientTopNavigation,
  }
}
